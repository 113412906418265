@font-face {
  font-family: "PFDinDisplayPro";
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  src: local('PFDinDisplayPro'), local('PFD in Display Pro'),
      url("fonts/pfdindisplaypro-reg.woff2") format("woff2");
}


@font-face {
  font-family: "PFDinDisplayPro";
  font-weight: 300;
  font-style: normal;
  font-display: auto;
  src: local('PFDinDisplayPro'), local('PFD in Display Pro'), 
    url("fonts/pfdindisplaypro-light.woff2") format("woff2");
}

@font-face {
  font-family: "PFDinDisplayPro";
  font-weight: 500;
  font-style: normal;
  font-display: auto;
  src: local('PFDinDisplayPro'), local('PFD in Display Pro'),
    url("fonts/pfdindisplaypro-med.woff2") format("woff2");
}

@font-face {
  font-family: "PFDinDisplayPro";
  font-weight: 700;
  font-style: normal;
  font-display: auto;
  src: local('PFDinDisplayPro'), local('PFD in Display Pro'), 
    url("fonts/pfdindisplaypro-bold.woff2") format("woff2");
}

@font-face {
  font-family: "GoodCyr";
  font-weight: 900;
  font-style: normal;
  font-display: auto;
  src: local('PFDinDisplayPro'), local('PFD in Display Pro'),
    url("fonts/goodcyr-wideblack.woff2") format("woff2");
}